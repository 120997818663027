import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BatchModal from "../modals/BatchModal";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../headersFooters/AdminHeader";
import AdminFooter from "../headersFooters/AdminFooter";

const initialFormData = {
  year: "",
  brand: "",
  variety: "",
  sport: "",
  card_number: "",
  player: "",
  grade: "",
  grade_description: "",
};

const gradeDescriptions = {
  "8": "Near Mint",
  "9": "Mint",
  "10": "Gem Mint",
};

function CardInput() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [batchCount, setBatchCount] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openBatchModal, setOpenBatchModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "grade" && {
        grade_description: gradeDescriptions[value] || "",
      }),
    }));
  };

  const isFormValid = () => {
    const requiredFields = [
      "year",
      "brand",
      "sport",
      "card_number",
      "player",
      "grade",
    ];
    return requiredFields.every((field) => formData[field]);
  };

  const handleError = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleBatchComplete = () => {
    setOpenBatchModal(false);
    setSnackbarMessage("Batch created successfully!");
    setOpenSnackbar(true);
    setFormData(initialFormData);
    setBatchCount(1);
  };

  const sharedStyles = {
    height: "56px",
    "& .MuiInputBase-root": {
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <div>
      <AdminHeader />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Card sx={{ maxWidth: 700, width: "100%", padding: 3, position: 'relative' }}>
          <CardContent>
            <Tooltip title="Close" arrow>
              <IconButton
                aria-label="close"
                onClick={() => navigate("/admin")}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                }}
              >
                <CloseIcon color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
            <Typography variant="h5" gutterBottom>
              Add New Card
            </Typography>

            <Grid container spacing={2}>
              {/* Row 1: YEAR and BRAND */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" sx={sharedStyles}>
                  <InputLabel
                    id="year-label"
                    sx={{ backgroundColor: "white", px: 1 }}
                  >
                    YEAR
                  </InputLabel>
                  <Select
                    labelId="year-label"
                    name="year"
                    value={formData.year}
                    onChange={handleInputChange}
                  >
                    {Array.from({ length: 50 }, (_, i) => 2025 - i).map(
                      (year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="BRAND"
                  name="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              {/* Row 2: VARIETY and SPORT */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="VARIETY"
                  name="variety"
                  value={formData.variety}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" sx={sharedStyles}>
                  <InputLabel
                    id="sport-label"
                    sx={{ backgroundColor: "white", px: 1 }}
                  >
                    SPORT
                  </InputLabel>
                  <Select
                    labelId="sport-label"
                    name="sport"
                    value={formData.sport}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Baseball">Baseball</MenuItem>
                    <MenuItem value="Basketball">Basketball</MenuItem>
                    <MenuItem value="Football">Football</MenuItem>
                    <MenuItem value="Golf">Golf</MenuItem>
                    <MenuItem value="Hockey">Hockey</MenuItem>
                    <MenuItem value="Soccer">Soccer</MenuItem>
                    <MenuItem value="Tennis">Tennis</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Row 3: CARD NUMBER and PLAYER */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CARD NUMBER"
                  name="card_number"
                  value={formData.card_number}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="PLAYER"
                  name="player"
                  value={formData.player}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              {/* Row 4: GRADE and GRADE DESCRIPTION */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" sx={sharedStyles}>
                  <InputLabel
                    id="grade-label"
                    sx={{ backgroundColor: "white", px: 1 }}
                  >
                    GRADE
                  </InputLabel>
                  <Select
                    labelId="grade-label"
                    name="grade"
                    value={formData.grade}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="GRADE DESCRIPTION"
                  name="grade_description"
                  value={formData.grade_description}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              {/* Left Side Elements */}
              <Box display="flex" alignItems="center">
                <TextField
                  label="Batch Count"
                  type="number"
                  value={batchCount}
                  onChange={(e) => {
                    const value = Math.max(1, parseInt(e.target.value || 1));
                    setBatchCount(value);
                  }}
                  sx={{ width: "120px", marginRight: 2 }}
                  inputProps={{ min: 1 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (!isFormValid()) {
                      handleError("Please fill in all required fields.");
                      return;
                    }
                    setOpenBatchModal(true);
                  }}
                >
                  Create Batch
                </Button>
              </Box>
              {/* Cancel Button to navigate back to Admin.js */}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/admin")}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <BatchModal
        open={openBatchModal}
        onClose={() => setOpenBatchModal(false)}
        formData={formData}
        batchCount={batchCount}
        onBatchComplete={handleBatchComplete}
        onError={handleError}
      />

      {/* Snackbar for success and error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
      <AdminFooter />
    </div>
  );
}

export default CardInput;
