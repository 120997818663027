import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "../../firebase";
import {
  doc,
  getDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Tooltip, // Import Tooltip
  IconButton, // Import IconButton
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";
import AdminHeader from "../headersFooters/AdminHeader";
import AdminFooter from "../headersFooters/AdminFooter";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon

function CardEdit() {
  const { id: cardId } = useParams();
  const navigate = useNavigate();
  const [cardIds, setCardIds] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [initialCardData, setInitialCardData] = useState({});
  const [imageFront, setImageFront] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const labelRefs = useRef({});

  const [isGroupEdit, setIsGroupEdit] = useState(false);

  useEffect(() => {
    if (cardId) {
      const ids = cardId.split(",");
      setCardIds(ids);
      setIsGroupEdit(ids.length > 1);
    }
  }, [cardId]);

  useEffect(() => {
    const fetchCardData = async () => {
      if (cardIds.length > 0) {
        const fetchedData = {};
        for (let id of cardIds) {
          const cardRef = doc(db, "cards", id);
          const cardSnap = await getDoc(cardRef);
          if (cardSnap.exists()) {
            fetchedData[id] = cardSnap.data();
            if (!isGroupEdit) {
              // For single card edit, load images
              const frontImageRef = ref(storage, `cards/${id}_front`);
              const backImageRef = ref(storage, `cards/${id}_back`);
              try {
                const frontUrl = await getDownloadURL(frontImageRef);
                setImageFront(frontUrl);
              } catch (error) {
                console.error("Error fetching front image:", error);
              }
              try {
                const backUrl = await getDownloadURL(backImageRef);
                setImageBack(backUrl);
              } catch (error) {
                console.error("Error fetching back image:", error);
              }
            }
          } else {
            setSnackbarMessage(`Card with ID ${id} not found.`);
            setOpenSnackbar(true);
            navigate("/admin");
            return;
          }
        }
        setCardData(mergeCardData(Object.values(fetchedData)));
        setInitialCardData(fetchedData);
      }
    };
    fetchCardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardIds, navigate]);

  // Function to merge card data for group editing
  const mergeCardData = (cardsArray) => {
    const mergedData = {};
    const fields = [
      "year",
      "brand",
      "variety",
      "sport",
      "card_number",
      "player",
      "grade",
      "grade_description",
    ];
    fields.forEach((field) => {
      const allValues = cardsArray.map((card) => card[field]);
      const allEqual = allValues.every((val) => val === allValues[0]);
      mergedData[field] = allEqual ? allValues[0] : "";
    });
    return mergedData;
  };

  const handleUpdate = async () => {
    if (cardData) {
      setConfirmDialogOpen(true);
    }
  };

  const confirmUpdate = async () => {
    try {
      const batch = writeBatch(db);
      const labelFields = [
        "year",
        "brand",
        "variety",
        "player",
        "card_number",
        "grade",
      ];

      for (let id of cardIds) {
        const cardRef = doc(db, "cards", id);
        const originalData = initialCardData[id];
        const updatedData = { ...originalData };

        // Update fields that have a value
        Object.keys(cardData).forEach((field) => {
          if (cardData[field] !== undefined) {
            updatedData[field] = cardData[field];
          }
        });

        if (!isGroupEdit) {
          // Handle image uploads for single card edit
          if (imageFront && typeof imageFront !== "string") {
            const frontStorageRef = ref(storage, `cards/${id}_front`);
            await uploadBytes(frontStorageRef, imageFront);
            const frontUrl = await getDownloadURL(frontStorageRef);
            updatedData.imageFront = frontUrl;
          }
          if (imageBack && typeof imageBack !== "string") {
            const backStorageRef = ref(storage, `cards/${id}_back`);
            await uploadBytes(backStorageRef, imageBack);
            const backUrl = await getDownloadURL(backStorageRef);
            updatedData.imageBack = backUrl;
          }
        }

        // Check if any label fields have changed
        const labelFieldsChanged = labelFields.some(
          (field) => updatedData[field] !== originalData[field]
        );

        if (labelFieldsChanged) {
          // Regenerate label
          const labelRefCurrent = labelRefs.current[id];
          if (labelRefCurrent) {
            const canvas = await html2canvas(labelRefCurrent, {
              scale: 3,
              useCORS: true,
              width: labelRefCurrent.offsetWidth,
              height: labelRefCurrent.offsetHeight,
            });
            const labelBlob = await new Promise((resolve) =>
              canvas.toBlob(resolve, "image/png")
            );
            const labelStorageRef = ref(storage, `labels/${id}_label.png`);
            await uploadBytes(labelStorageRef, labelBlob);
            const labelUrl = await getDownloadURL(labelStorageRef);
            updatedData.labelUrl = labelUrl;
          } else {
            console.error(`Label reference for card ID ${id} not found.`);
          }
        }

        // Update Firestore document with updated data
        batch.set(cardRef, updatedData);
      }

      await batch.commit();
      setSnackbarMessage("Cards updated successfully.");
      setOpenSnackbar(true);
      setConfirmDialogOpen(false);
      navigate("/admin");
    } catch (error) {
      console.error("Error updating cards:", error);
      setSnackbarMessage("Error updating cards.");
      setOpenSnackbar(true);
    }
  };

  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      for (let id of cardIds) {
        const cardRef = doc(db, "cards", id);
        const frontRef = ref(storage, `cards/${id}_front`);
        const backRef = ref(storage, `cards/${id}_back`);
        const qrCodeRef = ref(storage, `qr_codes/${id}_qrCode.png`);
        const labelRefStorage = ref(storage, `labels/${id}_label.png`);

        await deleteDoc(cardRef);
        await Promise.all([
          deleteObject(frontRef).catch(() => {}),
          deleteObject(backRef).catch(() => {}),
          deleteObject(qrCodeRef).catch(() => {}),
          deleteObject(labelRefStorage).catch(() => {}),
        ]);
      }

      setSnackbarMessage("Cards and associated data deleted successfully.");
      setOpenSnackbar(true);
      setDeleteDialogOpen(false);
      navigate("/admin");
    } catch (error) {
      console.error("Error deleting cards:", error);
      setSnackbarMessage("Error deleting cards.");
      setOpenSnackbar(true);
    }
  };

  const handleCancel = () => {
    navigate("/admin");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "grade" && {
        grade_description: gradeDescriptions[value] || "",
      }),
    }));
  };

  const gradeDescriptions = {
    "8": "Near Mint",
    "9": "Mint",
    "10": "Gem Mint",
  };

  const sharedStyles = {
    height: "56px",
    "& .MuiInputBase-root": {
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
    },
  };

  // Image upload handlers (only for single card edit)
  const handleImageChange = (file, type) => {
    if (type === "front") setImageFront(file);
    else if (type === "back") setImageBack(file);
  };

  const onDropFront = useCallback((acceptedFiles) => {
    handleImageChange(acceptedFiles[0], "front");
  }, []);

  const onDropBack = useCallback((acceptedFiles) => {
    handleImageChange(acceptedFiles[0], "back");
  }, []);

  const {
    getRootProps: getRootPropsFront,
    getInputProps: getInputPropsFront,
  } = useDropzone({
    onDrop: onDropFront,
    accept: "image/*",
    disabled: isGroupEdit,
  });

  const {
    getRootProps: getRootPropsBack,
    getInputProps: getInputPropsBack,
  } = useDropzone({
    onDrop: onDropBack,
    accept: "image/*",
    disabled: isGroupEdit,
  });

  // Function to render the label
  const renderLabel = (id, cardDataForId) => {
    // Convert physical dimensions to pixels (300 DPI)
    const mmToPixels = (mm) => (mm * 300) / 25.4;
    const labelWidthPx = mmToPixels(68);
    const labelHeightPx = mmToPixels(20.5);

    const gradeImageUrls = {
      "10": "/GMint10.svg",
      "9": "/Mint9.svg",
      "8": "/NMMint8.svg",
      default: "/default.svg",
    };

    const gradeImageUrl =
      gradeImageUrls[cardDataForId.grade] || gradeImageUrls["default"];

    return (
      <div
        key={id}
        style={{ position: "absolute", top: -9999, left: -9999 }}
        ref={(el) => (labelRefs.current[id] = el)}
      >
        <div
          style={{
            width: `${labelWidthPx}px`,
            height: `${labelHeightPx}px`,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            alignItems: "stretch",
            padding: "0px",
            border: "20px solid #0047ab",
            boxSizing: "border-box",
            backgroundColor: "white",
            margin: "10px",
          }}
        >
          {/* Left Column */}
          <div
            style={{
              display: "grid",
              gridTemplateRows: "repeat(5, 1fr)",
              paddingLeft: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {/* Row 1: Logo */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/SPA Logo.svg"
                alt="SPA Logo"
                style={{ height: "40px" }}
              />
            </div>
            {/* Row 2: Player Name */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                {cardDataForId.player}
              </Typography>
            </div>
            {/* Row 3: Year and Brand */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                {`${cardDataForId.year} ${cardDataForId.brand}`}
              </Typography>
            </div>
            {/* Row 4: Variety */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                {cardDataForId.variety}
              </Typography>
            </div>
            {/* Row 5: Card Number */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                {`Card #${cardDataForId.card_number}`}
              </Typography>
            </div>
          </div>

          {/* Right Column */}
          <div
            style={{
              display: "grid",
              gridTemplateRows: "80% 20%",
              alignItems: "stretch",
            }}
          >
            {/* Row 1: QR Code and Grade Image */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Column 1: QR Code */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <QRCodeCanvas
                  value={`https://spagrading.com/card/${id}`}
                  size={100}
                />
              </div>
              {/* Column 2: Grade Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {gradeImageUrl && (
                  <img
                    src={gradeImageUrl}
                    alt={`Grade ${cardDataForId.grade}`}
                    style={{ height: "100px", width: "auto" }}
                  />
                )}
              </div>
            </div>
            {/* Row 2: Document ID */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "10px",
                marginBottom: "30px",
              }}
            >
              <Typography
                variant="body2"
                style={{ fontSize: "30px", textAlign: "right" }}
              >
                {id}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <AdminHeader />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 2, flexDirection: "column" }}
      >
        {cardData ? (
          // Render the card only when cardData is available
          <Card sx={{ maxWidth: 1000, padding: 4, position: "relative" }}>
            <CardContent>
              <Tooltip title="Close" arrow>
                <IconButton
                  aria-label="close"
                  onClick={() => navigate("/admin")}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                >
                  <CloseIcon color="primary" fontSize="large" />
                </IconButton>
              </Tooltip>
              <Box>
                <Typography variant="h6" gutterBottom>
                  {isGroupEdit
                    ? `Group Edit (${cardIds.length} cards)`
                    : "Edit Card Details"}
                </Typography>

                <Grid container spacing={2}>
                  {/* Row 1: YEAR and BRAND */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="YEAR"
                      name="year"
                      value={cardData.year || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="BRAND"
                      name="brand"
                      value={cardData.brand || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  {/* Row 2: VARIETY and SPORT */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="VARIETY"
                      name="variety"
                      value={cardData.variety || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={sharedStyles}
                    >
                      <InputLabel
                        id="sport-label"
                        sx={{ backgroundColor: "white", px: 1 }}
                      >
                        SPORT
                      </InputLabel>
                      <Select
                        labelId="sport-label"
                        name="sport"
                        value={cardData.sport || ""}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="Baseball">Baseball</MenuItem>
                        <MenuItem value="Basketball">Basketball</MenuItem>
                        <MenuItem value="Football">Football</MenuItem>
                        <MenuItem value="Golf">Golf</MenuItem>
                        <MenuItem value="Hockey">Hockey</MenuItem>
                        <MenuItem value="Soccer">Soccer</MenuItem>
                        <MenuItem value="Tennis">Tennis</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Row 3: CARD NUMBER and PLAYER */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="CARD NUMBER"
                      name="card_number"
                      value={cardData.card_number || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="PLAYER"
                      name="player"
                      value={cardData.player || ""}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  {/* Row 4: GRADE and GRADE DESCRIPTION */}
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={sharedStyles}
                    >
                      <InputLabel
                        id="grade-label"
                        sx={{ backgroundColor: "white", px: 1 }}
                      >
                        GRADE
                      </InputLabel>
                      <Select
                        labelId="grade-label"
                        name="grade"
                        value={cardData.grade || ""}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="GRADE DESCRIPTION"
                      name="grade_description"
                      value={cardData.grade_description || ""}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  {/* Image Uploads - Only show when not in group edit */}
                  {!isGroupEdit && (
                    <>
                      {/* Row 5: Front Image and Back Image */}
                      <Grid item xs={12} sm={6}>
                        <Typography>Front Image:</Typography>
                        <Box
                          {...getRootPropsFront()}
                          sx={{
                            border: "1px dashed #ccc",
                            padding: 1,
                            textAlign: "center",
                            cursor: "pointer",
                            height: 300, // Set fixed height
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input {...getInputPropsFront()} />
                          {typeof imageFront === "string" ? (
                            <img
                              src={imageFront}
                              alt="Front"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : imageFront ? (
                            <img
                              src={URL.createObjectURL(imageFront)}
                              alt="Front"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <p>
                              Drag 'n' drop to replace front image, or click to
                              select
                            </p>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography>Back Image:</Typography>
                        <Box
                          {...getRootPropsBack()}
                          sx={{
                            border: "1px dashed #ccc",
                            padding: 1,
                            textAlign: "center",
                            cursor: "pointer",
                            height: 300, // Set fixed height
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input {...getInputPropsBack()} />
                          {typeof imageBack === "string" ? (
                            <img
                              src={imageBack}
                              alt="Back"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : imageBack ? (
                            <img
                              src={URL.createObjectURL(imageBack)}
                              alt="Back"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <p>
                              Drag 'n' drop to replace back image, or click to
                              select
                            </p>
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}

                  {/* Row 6: Buttons */}
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={3}
                    >
                      <Box>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleUpdate}
                        >
                          Update {isGroupEdit ? "Cards" : "Card"}
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={handleDelete}
                          sx={{ ml: 2 }}
                        >
                          Delete {isGroupEdit ? "Cards" : "Card"}
                        </Button>
                      </Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ) : (
          // Display a loader while data is being fetched
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Loading...
            </Typography>
          </Box>
        )}
      </Box>

      {/* Hidden label rendering for image generation */}
      {cardIds.map((id) => {
        const cardDataForId = {
          ...initialCardData[id],
          ...cardData,
        };
        return renderLabel(id, cardDataForId);
      })}

      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />

      {/* Confirmation Dialog for Update */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update{" "}
            {isGroupEdit ? "these cards" : "this card"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmUpdate} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {isGroupEdit ? "these cards" : "this card"} and all associated data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <AdminFooter />
    </div>
  );
}

export default CardEdit;
