import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { collection, addDoc, serverTimestamp, writeBatch, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import html2canvas from "html2canvas";
import { QRCodeCanvas } from "qrcode.react";
import { db, storage } from "../../firebase";

function BatchModal({ open, onClose, formData = {}, batchCount = 1, onBatchComplete, onError }) {
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const labelRefs = useRef([]);
  const [gradeImageUrls, setGradeImageUrls] = useState({});

  // New state for label size selection
  const [labelSizeOption, setLabelSizeOption] = useState("clear");
  const [customLabelWidth, setCustomLabelWidth] = useState(68);
  const [customLabelHeight, setCustomLabelHeight] = useState(20.5);

  // Reset labels when modal opens
  useEffect(() => {
    if (open) {
      setLabels([]);
      labelRefs.current = [];
      setGradeImageUrls({});
    }
  }, [open, formData, batchCount]);

  useEffect(() => {
    const loadGradeImages = () => {
      const gradeImages = {};
      labels.forEach((label) => {
        const grade = label.grade || "default";
        if (grade === "10") gradeImages[label.documentId] = "/GMint10.svg";
        else if (grade === "9") gradeImages[label.documentId] = "/Mint9.svg";
        else if (grade === "8") gradeImages[label.documentId] = "/NMMint8.svg";
        else gradeImages[label.documentId] = "/default.svg";
      });
      setGradeImageUrls(gradeImages);
    };

    if (labels.length > 0) loadGradeImages();
  }, [labels]);

  const isFormValid = () => {
    const requiredFields = ["year", "brand", "sport", "card_number", "player", "grade"];
    return requiredFields.every((field) => formData[field]);
  };

  const handleCreateBatch = async () => {
    if (!isFormValid()) {
      onError("Please fill in all required fields.");
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const baseUrl = "https://spagrading.com/card";
      const newLabels = [];

      for (let i = 0; i < batchCount; i++) {
        const newCardDocRef = await addDoc(collection(db, "cards"), {
          ...formData,
          timestamp: serverTimestamp(),
          isPrinted: false,
        });
        const documentId = newCardDocRef.id;
        const qrCodeUrl = `${baseUrl}/${documentId}`;

        newLabels.push({
          documentId,
          qrCodeUrl,
          player: formData.player,
          year: formData.year,
          brand: formData.brand,
          variety: formData.variety,
          cardNumber: formData.card_number,
          grade: formData.grade,
          gradeDescription: formData.grade_description,
        });
      }

      setLabels(newLabels);
    } catch (error) {
      console.error("Error during batch creation:", error);
      onError("An error occurred while creating the batch. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveLabels = async () => {
    setLoading(true);
    try {
      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        const labelRef = labelRefs.current[i];

        const canvas = await html2canvas(labelRef, {
          scale: 3,
          useCORS: true,
          width: labelRef.offsetWidth,
          height: labelRef.offsetHeight,
        });
        const labelBlob = await new Promise((resolve) => canvas.toBlob(resolve, "image/png"));
        const labelStorageRef = ref(storage, `labels/${label.documentId}_label.png`);
        await uploadBytes(labelStorageRef, labelBlob);

        const qrCanvas = labelRef.querySelector("canvas");
        const qrBlob = await new Promise((resolve) => qrCanvas.toBlob(resolve, "image/png"));
        const qrCodeStorageRef = ref(storage, `qr_codes/${label.documentId}_qrCode.png`);
        await uploadBytes(qrCodeStorageRef, qrBlob);
      }

      onBatchComplete();
    } catch (error) {
      console.error("Error saving labels:", error);
      onError("An error occurred while saving labels. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrintLabels = async () => {
    try {
      // Ensure labels are saved before attempting to print
      await handleSaveLabels();

      // Fetch the URLs of the saved labels
      const labelUrls = await Promise.all(
        labels.map(async (label) => {
          const labelRef = ref(storage, `labels/${label.documentId}_label.png`);
          const url = await getDownloadURL(labelRef);
          return { id: label.documentId, url };
        })
      );

      // Open a new window for printing
      const printWindow = window.open("", "_blank", "width=800,height=600");
      if (!printWindow) {
        throw new Error("Pop-up blocker is enabled. Please allow pop-ups for this website.");
      }

      // Generate HTML content with images
      const labelsHTML = labelUrls
        .map(
          (label) => `
          <div class="label">
            <img src="${label.url}" alt="Label for ${label.id}" />
          </div>`
        )
        .join("");

      // Get label dimensions
      const { widthMM: labelWidthMM, heightMM: labelHeightMM } = getLabelDimensions();

      // Write the HTML content to the print window
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Labels</title>
            <style>
              @media print {
                @page {
                  size: auto;
                  margin: 10mm;
                }
                body, html {
                  margin: 0;
                  padding: 0;
                }
                .label-container {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                }
                .label {
                  width: ${labelWidthMM}mm;
                  height: ${labelHeightMM}mm;
                  margin: 5mm;
                  box-sizing: border-box;
                  page-break-inside: avoid;
                }
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              body {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0;
                margin: 0;
              }
              .label {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
              }
              img {
                display: block;
              }
            </style>
          </head>
          <body>
            <div class="label-container">
              ${labelsHTML}
            </div>
          </body>
        </html>
      `);

      printWindow.document.close();

      // Function to wait for all images to load
      const waitForImagesToLoad = () => {
        return new Promise((resolve) => {
          const images = printWindow.document.images;
          if (images.length === 0) {
            resolve();
            return;
          }
          let loadedImagesCount = 0;
          const totalImages = images.length;

          const onImageLoadOrError = () => {
            loadedImagesCount++;
            if (loadedImagesCount === totalImages) {
              resolve();
            }
          };

          for (let img of images) {
            img.onload = onImageLoadOrError;
            img.onerror = onImageLoadOrError;
          }
        });
      };

      // Wait for images to load before printing
      await waitForImagesToLoad();

      // Trigger print
      printWindow.focus();
      printWindow.print();

      // Optionally close the window after printing
      printWindow.close();

      // After printing, update isPrinted field to true
      const batch = writeBatch(db);
      labels.forEach((label) => {
        const cardRef = doc(db, "cards", label.documentId);
        batch.update(cardRef, { isPrinted: true });
      });
      await batch.commit();

      // Optionally, update local state or notify the parent component
      onBatchComplete();
    } catch (error) {
      console.error("Error printing labels:", error);
      onError(error.message || "An error occurred while printing labels. Please try again.");
    }
  };

  // Function to get label dimensions based on selection
  const getLabelDimensions = () => {
    if (labelSizeOption === "clear") {
      return { widthMM: 68, heightMM: 20.5 };
    } else if (labelSizeOption === "frosted") {
      return { widthMM: 69, heightMM: 21.5 };
    } else {
      return { widthMM: customLabelWidth, heightMM: customLabelHeight };
    }
  };

  const renderLabels = () => {
    // Reset labelRefs.current to ensure it's always an array
    labelRefs.current = [];

    // Get selected label dimensions
    const { widthMM: labelWidthMM, heightMM: labelHeightMM } = getLabelDimensions();

    // Convert physical dimensions to pixels (300 DPI)
    const mmToPixels = (mm) => (mm * 300) / 25.4;
    const labelWidthPx = mmToPixels(labelWidthMM);
    const labelHeightPx = mmToPixels(labelHeightMM);

    return labels.map((label, index) => (
      <div
        key={index}
        ref={(el) => {
          labelRefs.current[index] = el;
        }}
        style={{
          width: `${labelWidthPx}px`,
          height: `${labelHeightPx}px`,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "stretch",
          padding: "0px",
          border: "20px solid #0047ab",
          boxSizing: "border-box",
          backgroundColor: "white",
          margin: "10px",
        }}
      >
        {/* Left Column */}
        <div
          style={{
            display: "grid",
            gridTemplateRows: "repeat(5, 1fr)",
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          {/* Row 1: Logo */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/SPA Logo.svg" alt="SPA Logo" style={{ height: "40px" }} />
          </div>
          {/* Row 2: Player Name */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" style={{ fontSize: "25px", textAlign: "left" }}>
              {label.player}
            </Typography>
          </div>
          {/* Row 3: Year and Brand */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" style={{ fontSize: "25px", textAlign: "left" }}>
              {`${label.year} ${label.brand}`}
            </Typography>
          </div>
          {/* Row 4: Variety */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" style={{ fontSize: "25px", textAlign: "left" }}>
              {label.variety}
            </Typography>
          </div>
          {/* Row 5: Card Number */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" style={{ fontSize: "25px", textAlign: "left" }}>
              {`Card #${label.cardNumber}`}
            </Typography>
          </div>
        </div>

        {/* Right Column */}
        <div
          style={{
            display: "grid",
            gridTemplateRows: "80% 20%",
            alignItems: "stretch",
          }}
        >
          {/* Row 1: QR Code and Grade Image */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Column 1: QR Code */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <QRCodeCanvas value={label.qrCodeUrl} size={100} />
            </div>
            {/* Column 2: Grade Image */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {gradeImageUrls[label.documentId] && (
                <img
                  src={gradeImageUrls[label.documentId]}
                  alt={`Grade ${label.grade}`}
                  style={{ height: "100px", width: "auto" }}
                />
              )}
            </div>
          </div>
          {/* Row 2: Document ID */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "10px",
              marginBottom: "30px",
            }}
          >
            <Typography variant="body2" style={{ fontSize: "30px", textAlign: "right" }}>
              {label.documentId}
            </Typography>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          maxWidth: 800,
          margin: "50px auto",
          borderRadius: 2,
          overflowY: "scroll",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Batch Labels
        </Typography>

        {/* Label Size Selection */}
        <Box display="flex" alignItems="center" mb={2}>
          <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2 }}>
            <InputLabel id="label-size-label">Label Size</InputLabel>
            <Select
              labelId="label-size-label"
              value={labelSizeOption}
              onChange={(e) => setLabelSizeOption(e.target.value)}
              label="Label Size"
            >
              <MenuItem value="clear">Clear (68mm x 20.5mm)</MenuItem>
              <MenuItem value="frosted">Frosted (69mm x 21.5mm)</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
          </FormControl>
          {labelSizeOption === "custom" && (
            <>
              <TextField
                label="Width (mm)"
                type="number"
                value={customLabelWidth}
                onChange={(e) => setCustomLabelWidth(parseFloat(e.target.value))}
                sx={{ width: 100, mr: 2 }}
              />
              <TextField
                label="Height (mm)"
                type="number"
                value={customLabelHeight}
                onChange={(e) => setCustomLabelHeight(parseFloat(e.target.value))}
                sx={{ width: 100 }}
              />
            </>
          )}
        </Box>

        {labels.length === 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateBatch}
            disabled={loading}
            sx={{ mb: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Generate Batch"}
          </Button>
        )}

        <Typography variant="body2" color="textSecondary">
          For best results, ensure your printer settings are set to 100% scaling.
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={2} justifyContent="space-between">
          {renderLabels()}
        </Box>

        {labels.length > 0 && (
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button variant="contained" color="primary" onClick={handleSaveLabels} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "Save Labels"}
            </Button>
            <Button variant="contained" color="secondary" onClick={handlePrintLabels}>
              Print Labels
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default BatchModal;

