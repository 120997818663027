import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Box,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Header from "../headersFooters/Header";
import Footer from "../headersFooters/Footer";

function Home() {
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // 0-based index for pages
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchCards = async () => {
      const cardsCollection = collection(db, "cards");
      const cardSnapshot = await getDocs(cardsCollection);
      setCards(
        cardSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    fetchCards();
  }, []);

  // Filtered cards based on search input
  const filteredCards = cards.filter((card) =>
    Object.values(card).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredCards.length / rowsPerPage);

  const paginatedCards = filteredCards.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0); // Reset to the first page after each new search input
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to the first page when rows per page changes
  };

  return (
    <div>
      <Header />

      {/* Introductory Text */}
      <Box sx={{ pt: { xs: 10, sm: 10 } }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            mt: 4,
            px: { xs: 2, sm: 0 }, // Added padding on mobile devices
          }}
        >
          <Typography variant="h4" gutterBottom>
            Certificate Verification
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ maxWidth: 600, mb: 2 }}
          >
            Verify the validity of SPA certification numbers using the search
            field below.  Always
            confirm certification numbers for collectibles purchased online
            after receipt.
          </Typography>

          {/* Search Field */}
          <TextField
            label="Search by any field (e.g., player, certificate number)"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            sx={{
              maxWidth: 600,
              mb: 3,
            }}
          />
        </Box>

        {/* Centered Table with Cards */}
        <Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
          <TableContainer
            component={Paper}
            sx={{
              width: "90%",
              maxWidth: "100%",
              overflowX: "auto", // Added for responsiveness
            }}
          >
            <Table>
              <TableHead>
              <TableRow sx={{ backgroundColor: "#e6f2ff" }}>
                  <TableCell></TableCell> {/* Front Image Column */}
                  <TableCell>Certificate Number</TableCell>
                  <TableCell>Sport</TableCell>
                  <TableCell>Player</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Variety</TableCell>
                  <TableCell>Card Number</TableCell>
                  {/* <TableCell>Rating</TableCell> */}
                  <TableCell></TableCell> {/* QR Code Column */}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedCards.map((card) => (
                  <TableRow
                    key={card.id}
                    component={Link}
                    to={`/card/${card.id}`}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <TableCell>
                      {card.imageFront && (
                        <img
                          src={card.imageFront}
                          alt="Card Front"
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>{card.id}</TableCell>
                    <TableCell>{card.sport}</TableCell>
                    <TableCell>{card.player}</TableCell>
                    <TableCell>{card.year}</TableCell>
                    <TableCell>{card.brand}</TableCell>
                    <TableCell>{card.variety}</TableCell>
                    <TableCell>{card.card_number}</TableCell>
                    {/* <TableCell>{card.grade}</TableCell> */}
                    <TableCell>
                      {card.qr_code && (
                        <img
                          src={card.qr_code}
                          alt="QR Code"
                          style={{ width: 50, height: 50 }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

                          {/* Pagination */}
            <Box
              display="flex"
              flexWrap="wrap" // Allows wrapping on smaller screens
              justifyContent="space-between"
              alignItems="center"
              sx={{
                p: 2,
                gap: 2, // Adds spacing between wrapped items
              }}
            >
              {/* Rows Per Page Selector */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-start" }} // Center on mobile
                sx={{ flex: { xs: "1 1 100%", sm: "0 1 auto" } }} // Full width on mobile
              >
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Records Per Page:
                </Typography>
                <Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  size="small"
                  sx={{ minWidth: "80px" }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </Box>

              {/* Page Navigation */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-end" }} // Center on mobile
                sx={{ flex: { xs: "1 1 100%", sm: "0 1 auto" } }} // Full width on mobile
              >
                <IconButton
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 0}
                >
                  <ArrowBack />
                </IconButton>
                <Typography variant="body2" sx={{ mx: 2 }}>
                  Page {currentPage + 1} of {totalPages}
                </Typography>
                <IconButton
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages - 1}
                >
                  <ArrowForward />
                </IconButton>
              </Box>
            </Box>

          </TableContainer>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}

export default Home;
