import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { storage, db } from "../../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { writeBatch, doc } from "firebase/firestore";

function MassPrintModal({ open, onClose, selectedCards, onPrintComplete }) {
  const [labels, setLabels] = useState([]);

  // State for label size selection
  const [labelSizeOption, setLabelSizeOption] = useState("clear");
  const [customLabelWidth, setCustomLabelWidth] = useState(68);
  const [customLabelHeight, setCustomLabelHeight] = useState(20.5);

  useEffect(() => {
    const fetchLabels = async () => {
      const labelUrls = await Promise.all(
        selectedCards.map(async (cardId) => {
          try {
            const labelRef = ref(storage, `labels/${cardId}_label.png`);
            const url = await getDownloadURL(labelRef);
            return { id: cardId, url };
          } catch (error) {
            console.error("Error fetching label:", error);
            return null;
          }
        })
      );
      setLabels(labelUrls.filter(Boolean));
    };

    fetchLabels();
  }, [selectedCards]);

  const handlePrint = async () => {
    const printWindow = window.open("", "_blank", "width=800,height=600");
    if (!printWindow) {
      console.error("Pop-up blocker is enabled. Please allow pop-ups for this website.");
      return;
    }

    const labelsHTML = labels
      .map(
        (label) => `
        <div class="label">
          <img src="${label.url}" alt="Label for ${label.id}" />
        </div>`
      )
      .join("");

    // Get label dimensions
    const { widthMM: labelWidthMM, heightMM: labelHeightMM } = getLabelDimensions();

    printWindow.document.write(`
      <html>
        <head>
          <title>Print Labels</title>
          <style>
            @media print {
              @page {
                size: auto;
                margin: 10mm;
              }
              body, html {
                margin: 0;
                padding: 0;
              }
              .label-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              }
              .label {
                width: ${labelWidthMM}mm;
                height: ${labelHeightMM}mm;
                margin: 5mm;
                box-sizing: border-box;
                page-break-inside: avoid;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
            body {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              padding: 0;
              margin: 0;
            }
            .label {
              box-sizing: border-box;
              margin: 0;
              padding: 0;
            }
            img {
              display: block;
            }
          </style>
        </head>
        <body>
          <div class="label-container">
            ${labelsHTML}
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();

    // Function to wait for all images to load
    const waitForImagesToLoad = () => {
      return new Promise((resolve) => {
        const images = printWindow.document.images;
        if (images.length === 0) {
          resolve();
          return;
        }
        let loadedImagesCount = 0;
        const totalImages = images.length;

        const onImageLoadOrError = () => {
          loadedImagesCount++;
          if (loadedImagesCount === totalImages) {
            resolve();
          }
        };

        for (let img of images) {
          img.onload = onImageLoadOrError;
          img.onerror = onImageLoadOrError;
        }
      });
    };

    // Wait for images to load before printing
    await waitForImagesToLoad();

    // Trigger print
    printWindow.focus();
    printWindow.print();

    // Optionally close the window after printing
    printWindow.close();

    // After printing, update isPrinted field to true
    const batch = writeBatch(db);
    selectedCards.forEach((cardId) => {
      const cardRef = doc(db, "cards", cardId);
      batch.update(cardRef, { isPrinted: true });
    });
    await batch.commit();

    // Notify Admin.js that printing is complete
    if (onPrintComplete) {
      onPrintComplete();
    }

    // Close the modal
    onClose();
  };

  // Function to get label dimensions based on selection
  const getLabelDimensions = () => {
    if (labelSizeOption === "clear") {
      return { widthMM: 68, heightMM: 20.5 };
    } else if (labelSizeOption === "frosted") {
      return { widthMM: 69, heightMM: 21.5 };
    } else {
      return { widthMM: customLabelWidth, heightMM: customLabelHeight };
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          width: "100%",
          maxWidth: "1200px",
          height: "100vh",
          overflow: "auto",
        }}
      >
        {/* Label Size Selection */}
        <Box display="flex" alignItems="center" mb={2}>
          <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2 }}>
            <InputLabel id="label-size-label">Label Size</InputLabel>
            <Select
              labelId="label-size-label"
              value={labelSizeOption}
              onChange={(e) => setLabelSizeOption(e.target.value)}
              label="Label Size"
            >
              <MenuItem value="clear">Clear (68mm x 20.5mm)</MenuItem>
              <MenuItem value="frosted">Frosted (69mm x 21.5mm)</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
          </FormControl>
          {labelSizeOption === "custom" && (
            <>
              <TextField
                label="Width (mm)"
                type="number"
                value={customLabelWidth}
                onChange={(e) => setCustomLabelWidth(parseFloat(e.target.value))}
                sx={{ width: 100, mr: 2 }}
              />
              <TextField
                label="Height (mm)"
                type="number"
                value={customLabelHeight}
                onChange={(e) => setCustomLabelHeight(parseFloat(e.target.value))}
                sx={{ width: 100 }}
              />
            </>
          )}
        </Box>

        {/* Container for Labels */}
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gap={1}
          sx={{
            "@media print": {
              "*": { margin: 0, padding: 0 },
              "body, html": { width: "100%", height: "100%", margin: 0, padding: 0 },
              "img": { display: "block" },
            },
          }}
        >
          {labels.map((label) => (
            <Box
              key={label.id}
              sx={{
                width: `${getLabelDimensions().widthMM}mm`,
                height: `${getLabelDimensions().heightMM}mm`,
                padding: 0,
                boxShadow: "none",
                margin: "5mm",
              }}
            >
              <img
                src={label.url}
                alt={`Label for ${label.id}`}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          ))}
        </Box>

        {/* Action Buttons */}
        <Box display="flex" justifyContent="flex-start" mt={2} className="no-print">
          <Button variant="contained" onClick={handlePrint} sx={{ mr: 2 }}>
            Print Labels
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default MassPrintModal;
