import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  Typography,
  Box,
  CardMedia,
  Divider,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import Header from "../headersFooters/Header";
import CloseIcon from "@mui/icons-material/Close";

function CardDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [card, setCard] = useState(null);

  useEffect(() => {
    const fetchCard = async () => {
      const docRef = doc(db, "cards", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setCard(docSnap.data());
      }
    };
    fetchCard();
  }, [id]);

  return (
    <div>
      <Header />
      {/* Main Content Offset to Avoid Overlap */}
      <Box sx={{ pt: { xs: 8, sm: 10 } }}>
        {card ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              padding: { xs: 2, sm: 4 },
              width: "100%",
              maxWidth: 800,
              margin: "0 auto",
            }}
          >
            {/* Card Details Section */}
            <Paper
              elevation={3}
              sx={{
                position: "relative",
                width: "100%",
                padding: { xs: 2, sm: 3 },
                marginBottom: 4,
                mx: { xs: 2, sm: 0 },
              }}
            >
              <Tooltip title="Close" arrow>
                <IconButton
                  aria-label="close"
                  onClick={() => navigate("/")}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                >
                  <CloseIcon color="primary" fontSize="large" />
                </IconButton>
              </Tooltip>
              <Typography variant="h5" textAlign="center" gutterBottom>
                {card.player}
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                color="textSecondary"
                gutterBottom
              >
                SPA Certification Number: {id}
              </Typography>

              <Divider sx={{ marginY: 2 }} />

              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="center"
              >
                <Typography variant="body2" color="textSecondary">
                  <strong>Sport:</strong> {card.sport}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Brand:</strong> {card.brand}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {card.variety}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Year:</strong> {card.year}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Card Number:</strong> {card.card_number}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Grade:</strong> {card.grade}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Grade Description:</strong> {card.grade_description}
                </Typography>
              </Box>
            </Paper>

            {/* Display Images */}
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="center"
              alignItems="center"
              gap={3}
              width="100%"
              sx={{
                px: { xs: 2, sm: 0 }, // Added horizontal padding on mobile
                mb: 4, // Added bottom margin
              }}
            >
              {card.imageFront && (
                <CardMedia
                  component="img"
                  image={card.imageFront}
                  alt="Card Front"
                  sx={{
                    width: { xs: "90%", sm: "45%" },
                    height: "auto",
                    maxWidth: { xs: 300, sm: 400 },
                    borderRadius: 2,
                    boxShadow: 3,
                    mx: "auto", // Center the image
                  }}
                />
              )}
              {card.imageBack && (
                <CardMedia
                  component="img"
                  image={card.imageBack}
                  alt="Card Back"
                  sx={{
                    width: { xs: "90%", sm: "45%" },
                    height: "auto",
                    maxWidth: { xs: 300, sm: 400 },
                    borderRadius: 2,
                    boxShadow: 3,
                    mx: "auto", // Center the image
                  }}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </div>
  );
}

export default CardDetail;
