import React from 'react';
import { Box, Typography, } from '@mui/material';

const Footer = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        backgroundColor: '#ffffff',
        padding: '10px 20px', 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        flexWrap: 'wrap', 
        mt: 4 
      }}
    >
      {/* <Typography 
        variant="body2" 
        color="textSecondary" 
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        Visit my store: {' '}
        <Link 
          href="https://www.ebay.com/usr/picklesandchips" 
          target="_blank" 
          rel="noopener noreferrer" 
          sx={{ ml: 1 }}
        >
          <img 
            src="/ebay.svg" 
            alt="eBay Logo" 
            style={{
              width: 50, 
              height: 50,
              verticalAlign: 'middle',
              marginBottom: '-1px', 
            }} 
          />
        </Link>
      </Typography> */}

      <Typography variant="body2" color="textSecondary">
        © 2024
      </Typography>
    </Box>
  );
};

export default Footer;
