import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Checkbox,
  Tooltip,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Menu,
  Divider,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { db, storage } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { ref, deleteObject, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MassPrintModal from "../modals/MassPrintModal";
import AdminHeader from "../headersFooters/AdminHeader";
import AdminFooter from "../headersFooters/AdminFooter";

function Admin() {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedCards, setSelectedCards] = useState([]);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // 0-based index
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10

  const [anchorEl, setAnchorEl] = useState(null);
  const openActionsMenu = Boolean(anchorEl);

  // Fetch cards function
  const fetchCards = async () => {
    try {
      // Fetch cards ordered by timestamp descending (newest first)
      const cardsCollection = collection(db, "cards");
      const cardsQuery = query(cardsCollection, orderBy("timestamp", "desc"));
      const cardSnapshot = await getDocs(cardsQuery);

      const cardsData = await Promise.all(
        cardSnapshot.docs.map(async (docSnap) => {
          const cardData = { ...docSnap.data(), id: docSnap.id };

          // Fetch imageFront URL
          try {
            const imageFrontRef = ref(storage, `cards/${cardData.id}_front`);
            const imageFrontUrl = await getDownloadURL(imageFrontRef);
            cardData.imageFront = imageFrontUrl;
          } catch (error) {
            console.error("Error fetching front image:", error);
            cardData.imageFront = null;
          }

          // Fetch labelUrl if needed
          try {
            const labelRef = ref(storage, `labels/${cardData.id}_label.png`);
            const labelUrl = await getDownloadURL(labelRef);
            cardData.labelUrl = labelUrl;
          } catch (error) {
            console.error("Error fetching label image:", error);
            cardData.labelUrl = null;
          }

          // Ensure isPrinted field is set (in case it's missing in older documents)
          if (cardData.isPrinted === undefined) {
            cardData.isPrinted = false;
          }

          return cardData;
        })
      );

      setCards(cardsData);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const goToCardInput = () => {
    navigate("/cardinput");
  };

  const goToCardEdit = (id) => {
    navigate(`/cardedit/${id}`);
  };

  const handleDeleteClick = (id) => {
    setCardToDelete(id);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const cardRef = doc(db, "cards", cardToDelete);
      const frontRef = ref(storage, `cards/${cardToDelete}_front`);
      const backRef = ref(storage, `cards/${cardToDelete}_back`);
      const qrCodeRef = ref(storage, `qr_codes/${cardToDelete}_qrCode.png`);
      const labelRef = ref(storage, `labels/${cardToDelete}_label.png`);

      await deleteDoc(cardRef);
      await Promise.all([
        deleteObject(frontRef).catch(() => {}),
        deleteObject(backRef).catch(() => {}),
        deleteObject(qrCodeRef).catch(() => {}),
        deleteObject(labelRef).catch(() => {}),
      ]);

      setCards((prevCards) =>
        prevCards.filter((card) => card.id !== cardToDelete)
      );
      setSnackbarMessage("Card and associated data deleted successfully.");
      setOpenSnackbar(true);
      setDeleteDialogOpen(false);
      setCardToDelete(null);
      setSelectedCards((prev) => prev.filter((id) => id !== cardToDelete));
    } catch (error) {
      console.error("Error deleting card:", error);
      setSnackbarMessage("An error occurred while deleting the card.");
      setOpenSnackbar(true);
      setDeleteDialogOpen(false);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedCards((prev) =>
      prev.includes(id) ? prev.filter((cardId) => cardId !== id) : [...prev, id]
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const visibleCardIds = paginatedCards.map((card) => card.id);
      setSelectedCards((prevSelected) => [
        ...new Set([...prevSelected, ...visibleCardIds]),
      ]);
    } else {
      const visibleCardIds = paginatedCards.map((card) => card.id);
      setSelectedCards((prevSelected) =>
        prevSelected.filter((id) => !visibleCardIds.includes(id))
      );
    }
  };

  const handlePrintLabels = () => {
    if (selectedCards.length > 0) {
      setOpenPrintModal(true);
    } else {
      setSnackbarMessage("No labels selected for printing.");
      setOpenSnackbar(true);
    }
  };

  const handleGroupEdit = () => {
    if (selectedCards.length > 0) {
      navigate(`/cardedit/${selectedCards.join(",")}`);
    } else {
      setSnackbarMessage("No cards selected for group edit.");
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(0); // Reset to the first page after each new search input
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to the first page when rows per page changes
  };

  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const handleActionSelect = (action) => {
    if (action === "add") {
      goToCardInput();
    } else if (action === "edit") {
      handleGroupEdit();
    } else if (action === "print") {
      handlePrintLabels();
    }
    handleActionsClose();
  };

  const filteredCards = cards.filter((card) =>
    Object.values(card).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredCards.length / rowsPerPage);
  const paginatedCards = filteredCards.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  const areAllVisibleRowsSelected =
    paginatedCards.length > 0 &&
    paginatedCards.every((card) => selectedCards.includes(card.id));

  // Callback function to refresh data after printing is complete
  const handlePrintComplete = () => {
    fetchCards();
  };

  return (
    <div>
      <AdminHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        {/* Removed Legend as per your request */}

        <Box
          sx={{
            width: "90%",
            mb: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
            label="Search by any field (e.g., player, certificate number)"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
          />
          <Button
            variant="outlined"
            sx={{
              ml: 2,
              color: "#0047ab",
              borderColor: "#0047ab",
            }}
            onClick={handleActionsClick}
            endIcon={<ArrowDropDownIcon />}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={openActionsMenu}
            onClose={handleActionsClose}
          >
            <MenuItem onClick={() => handleActionSelect("add")}>
              Add New Card
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleActionSelect("edit")}>
              Group Edit
            </MenuItem>
            <MenuItem onClick={() => handleActionSelect("print")}>
              Print Labels
            </MenuItem>
          </Menu>
        </Box>

        <TableContainer component={Paper} sx={{ width: "90%" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#e6f2ff" }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={areAllVisibleRowsSelected}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>Certificate Number</TableCell>
                {/* <TableCell>Sport</TableCell> */}
                <TableCell>Year</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Variety</TableCell>
                <TableCell>Player</TableCell>
                <TableCell>Number</TableCell>
                <TableCell align="center">
                  <Tooltip title="Add New Card" arrow>
                    <IconButton onClick={goToCardInput}>
                      <AddCircleIcon color="primary" fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedCards.map((card) => (
                <TableRow
                  key={card.id}
                  sx={{
                    backgroundColor: card.isPrinted ? "white" : "#ffe6e6",
                    height: 60, // Set a fixed height
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCards.includes(card.id)}
                      onChange={() => handleCheckboxChange(card.id)}
                    />
                  </TableCell>
                  <TableCell style={{ width: 60 }}>
                    {card.imageFront ? (
                      <img
                        src={card.imageFront}
                        alt="Front of Card"
                        style={{
                          width: 50,
                          height: 50,
                          objectFit: "contain",
                          display: "block",
                          margin: "0 auto",
                        }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        No Image
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/card/${card.id}`}
                      style={{ textDecoration: "none", color: "blue" }}
                    >
                      {card.id}
                    </Link>
                  </TableCell>
                  {/* <TableCell>{card.sport}</TableCell> */}
                  <TableCell>{card.year}</TableCell>
                  <TableCell>{card.brand}</TableCell>
                  <TableCell>{card.variety}</TableCell>
                  <TableCell>{card.player}</TableCell>
                  <TableCell>{card.card_number}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => goToCardEdit(card.id)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(card.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Pagination */}
          <Box
            display="flex"
            flexWrap="wrap" 
            justifyContent="space-between"
            alignItems="center"
            sx={{
              p: 2,
              gap: 2, 
            }}
          >
            {/* Rows Per Page Selector */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-start" }} // Center on mobile
              sx={{ flex: { xs: "1 1 100%", sm: "0 1 auto" } }} // Full width on mobile
            >
              <Typography variant="body2" sx={{ mr: 1 }}>
                Records Per Page:
              </Typography>
              <Select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                size="small"
                sx={{ minWidth: "80px" }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Box>

            {/* Page Navigation */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-end" }} // Center on mobile
              sx={{ flex: { xs: "1 1 100%", sm: "0 1 auto" } }} // Full width on mobile
            >
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 0}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="body2" sx={{ mx: 2 }}>
                Page {currentPage + 1} of {totalPages}
              </Typography>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages - 1}
              >
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>
        </TableContainer>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this card and all associated data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {openPrintModal && (
        <MassPrintModal
          open={openPrintModal}
          onClose={() => setOpenPrintModal(false)}
          selectedCards={selectedCards}
          onPrintComplete={handlePrintComplete} // Pass the callback function
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
      <AdminFooter />
    </div>
  );
}

export default Admin;
