import React from 'react';
import { Box, Typography } from '@mui/material';

const AdminFooter = () => {
  return (
    <Box 
      component="footer" 
      sx={{ 
        backgroundColor: '#ffffff', 
        padding: '10px 20px', 
        textAlign: 'center', 
        mt: 4 
      }}
    >
      <Typography variant="body2" color="textSecondary">
        Version 2.0
      </Typography>
    </Box>
  );
};

export default AdminFooter;
